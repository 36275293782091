import React, { useState, useEffect, forwardRef  } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';
import { useTranslation } from "react-i18next";
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr';
import { useLanguage } from "../LanguageContext";

import "./styles/Reservation.css";

registerLocale('fr', fr);

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE);

const Reservation = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const { language } = useLanguage();
    
    const [selectedGite, setSelectedGite] = useState(searchParams.get('gite') || '');
    const [arrivalDate, setArrivalDate] = useState(searchParams.get('arrival') || '');
    const [departureDate, setDepartureDate] = useState(searchParams.get('departure') || '');
    const [isDoublePeriod, setIsDoublePeriod] = useState(false);
    const [isHighPeriod, setIsHighPeriod] = useState(false);
    const [unavailableStartDates, setUnavailableStartDates] = useState([]);
    const [unavailableEndDates, setUnavailableEndDates] = useState([]);
    const [reservationsDates, setReservationsDates] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [nights, setNights] = useState(0);
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [address, setAddress] = useState("");
    const [adult, setAdult] = useState();
    const [child, setChild] = useState();
    const [tax, setTax] = useState("1.09");
    const [TVA, setTVA] = useState("");
    const [showPaymentForm, setShowPaymentForm] = useState(false);
    const [gites, setGites] = useState([]);
    const [isConditionChecked, setIsConditionChecked] = useState(false);
    const [clientSecret, setClientSecret] = useState(null);
    const [minArrivalDate, setMinArrivalDate] = useState(new Date());
    const [reservationId, setReservationId] = useState("");
    const [fieldEmpty, setFieldEmpty] = useState(false);
    const [maxDepartureDate, setMaxDepartureDate] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 699);
    const apiUrl = process.env.REACT_APP_API_URL;

    const giteDetails = gites.find(gite => gite.id === parseInt(selectedGite, 10));

    const appearance = {
        theme: 'stripe',
        labels: 'floating'
    };

    const CustomArrivalInputComponent = forwardRef(({ value, onClick, disabled }, ref) => (
        <button 
            className={`reservation-dates-calendar ${disabled ? 'disabled' : ''}`} 
            onClick={onClick} 
            ref={ref} 
            disabled={disabled} 
            style={{ cursor: disabled ? 'not-allowed' : 'pointer' }} 
        >
            {value || t("reservation.arrivalDate")}
        </button>
    ));

    const CustomDepartureInputComponent = forwardRef(({ value, onClick, disabled }, ref) => (
        <button 
            className={`reservation-dates-calendar ${disabled ? 'disabled' : ''}`} 
            onClick={onClick} 
            ref={ref} 
            disabled={disabled}
            style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
        >
            {value || t("reservation.departureDate")}
        </button>
    ));

    useEffect(() => {
        if (arrivalDate) {
            const firstUnavailableAfterArrival = reservationsDates
                .map(date => new Date(date))
                .sort((a, b) => a - b)
                .find(date => date > arrivalDate);

            if (firstUnavailableAfterArrival) {
                const dayBefore = new Date(firstUnavailableAfterArrival);
                dayBefore.setDate(dayBefore.getDate() - 0);
                setMaxDepartureDate(dayBefore);
            } else {
                setMaxDepartureDate(null);
            }
        }
    }, [arrivalDate, reservationsDates]);

    useEffect(() => {
        const fetchUnavailableDates = async () => {
            try {
                const response = await fetch(`${apiUrl}/UnavailableDate/${selectedGite}`);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                const { reservations/*, seasonPeriods, weekPeriod*/ } = data;
    
                const getDatesInRange = (startDate, endDate, excludeDays = [], excludeStart = false) => {
                    const start = new Date(startDate);
                    const end = new Date(endDate);
                    const dates = [];
    
                    const adjustedStart = excludeStart ? new Date(start.setDate(start.getDate() + 1)) : start;

                    for (let date = new Date(adjustedStart); date <= end; date.setDate(date.getDate() + 1)) {
                        if (!excludeDays.includes(date.getDay())) {
                            dates.push(new Date(date).toISOString().split('T')[0]);
                        }
                    }
    
                    return dates;
                };
    
                let allReservationStartDates = [];
                reservations.forEach(reservation => {
                    const dates = getDatesInRange(reservation.arrival_date, reservation.departure_date);
                    allReservationStartDates = allReservationStartDates.concat(dates);
                });

                let allReservationEndDates = [];
                reservations.forEach(reservation => {
                    const dates = getDatesInRange(reservation.arrival_date, reservation.departure_date, [], true);
                    allReservationEndDates = allReservationEndDates.concat(dates);
                });
                
                /*let allSeasonDates = [];
                seasonPeriods.forEach(period => {
                    if (period.date_start1 && period.date_end1) {
                        const dates = getDatesInRange(period.date_start1, period.date_end1, [weekPeriod]);
                        allSeasonDates = allSeasonDates.concat(dates);
                    }
                    if (period.date_start2 && period.date_end2) {
                        const dates = getDatesInRange(period.date_start2, period.date_end2, [weekPeriod]);
                        allSeasonDates = allSeasonDates.concat(dates);
                    }
                });*/
    
                const combinedStartDates = allReservationStartDates//[...new Set([...allReservationStartDates, ...allSeasonDates])]; 
                const combinedEndDates = allReservationEndDates//[...new Set([...allReservationEndDates, ...allSeasonDates])]; 
    
                setUnavailableStartDates(combinedStartDates);
                setUnavailableEndDates(combinedEndDates);
                setReservationsDates(allReservationStartDates);

            } catch (error) {
                console.error("Erreur lors de la récupération des dates indisponibles:", error);
            }
        };
    
        if (selectedGite) {
            fetchUnavailableDates();
        }
    }, [selectedGite, apiUrl]);

    const handleArrivalDateChange = (date) => {
        setArrivalDate(date);
        setDepartureDate('');
    };    
    
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 599);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const fetchTotalPrice = async () => {
            if (selectedGite && arrivalDate && departureDate) {
                try {
                    const response = await fetch(`${apiUrl}/getReservationPrice`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            giteId: selectedGite,
                            startDate: arrivalDate,
                            endDate: departureDate,
                            adultNumber: adult
                        }),
                    });
                    if (!response.ok) {
                        throw new Error('Problème lors de la récupération du prix total');
                    }
                    const data = await response.json();
                    setTotalPrice(data.totalPrice);
                    setNights(data.nights);
                    setIsDoublePeriod(data.isDoublePeriod);
                    setTax(data.tax);
                    if (data.seasonIds?.includes(3) || data.seasonIds?.includes(4) || data.seasonIds.length === 0) {
                        setIsHighPeriod(true);
                    } else {
                        setIsHighPeriod(false);
                    }                    

                } catch (error) {
                    console.error('Erreur lors de la récupération du prix total:', error);
                }
            }
        };

        fetchTotalPrice();
    }, [selectedGite, arrivalDate, departureDate, adult, apiUrl]);

    const minDepartureDate = arrivalDate && new Date(arrivalDate);
    if (minDepartureDate) {
        minDepartureDate.setDate(minDepartureDate.getDate() + 1);
    }

    const maxArrivalDate = departureDate && new Date(departureDate);
    if (maxArrivalDate) {
        maxArrivalDate.setDate(maxArrivalDate.getDate() - 1);
    }

    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    const handlePayment = async () => {
        if (!email || !name || !name || !address || ! arrivalDate || !departureDate || !isConditionChecked || nights < 2 || !child || !adult) {
            setFieldEmpty(true);
            return;
        }

        const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
        if (!emailRegex.test(email)) {
            alert(t("reservation.minimumNight"));
            return;
        }

        const arrival = new Date(arrivalDate);
        const departure = new Date(departureDate);

        const diffTime = Math.abs(departure - arrival);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

        if (diffDays < 4 && isHighPeriod) {
            alert("La réservation doit être de minimum quatres nuits.");
            return;
        }
        else if (diffDays < 2) {
            alert("La réservation doit être de minimum deux nuits.");
            return;
        }
    
        try {
            const response = await fetch(`${apiUrl}/createReservation`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email,
                    giteId: selectedGite,
                    arrivalDate,
                    departureDate,
                    name,
                    address,
                    TVA,
                    adultNumber: adult
                }),
            });
    
            const data = await response.json();
    
            if (!response.ok) {
                throw new Error(data.message || "Ces dates ne sont plus disponibles.");
            }
    
            setShowPaymentForm(true); 
            setReservationId(data.reservationId);
        } catch (error) {
            console.error("Erreur lors de la création de la réservation:", error);
            alert(error.message);
        }
    };    

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handleAddressChange = (event) => {
        setAddress(event.target.value);
    };

    const handleAdultChange = (event) => {
        setAdult(event.target.value);
    };

    const handleChildChange = (event) => {
        setChild(event.target.value);
    };

    const handleTVAChange = (event) => {
        setTVA(event.target.value);
    };

    const handleCheckboxChange = (event) => {
        setIsConditionChecked(event.target.checked);
    };

    useEffect(() => {
        fetch(`/data/${language}/gites.json`)
          .then(response => response.json())
          .then(data => setGites(data.gites))
          .catch(error => console.error('Erreur lors du chargement des gîtes:', error));
    }, [language]);  

    const handleChangeGite = (e) => {
        const newGiteId = e.target.value;
        setSelectedGite(newGiteId);
    
        setArrivalDate('');
        setDepartureDate('');
        setTotalPrice(0);
        setNights(0);
    };
    

    return (
        <main className="reservation">
            {!showPaymentForm && (
            <div className="reservation-container">
                {!isMobile && (
                    <div className="reservation-image">
                        {giteDetails && (<img src={giteDetails.imageScroll[0]} alt={giteDetails?.name} />)}
                    </div>
                )}
                {!isMobile && (
                    <div className={giteDetails ? "reservation-divider" : ""}></div>
                )}
                <div className="reservation-details">
                    <div className="reservation-customer">
                        <div className="reservation-customer-info">
                            <label htmlFor="email">{t("reservation.email")}{fieldEmpty && !email &&(<span>{t("reservation.obligatoryField")}</span>)}</label>
                            <input id="email" type="email" value={email} onChange={handleEmailChange} />
                        </div>
                        <div className="reservation-customer-info">
                            <label htmlFor="text">{t("reservation.name")}{fieldEmpty && !name &&(<span>{t("reservation.obligatoryField")}</span>)}</label>
                            <input id="name" type="name" value={name} onChange={handleNameChange} />
                        </div>
                    </div>
                    <div className="reservation-customer">
                        <div className="reservation-customer-info">
                            <label htmlFor="text">{t("reservation.address")}{fieldEmpty && !address &&(<span>{t("reservation.obligatoryField")}</span>)}</label>
                            <input id="address" type="address" value={address} onChange={handleAddressChange} />
                        </div>
                        <div className="reservation-customer-info">
                            <label htmlFor="text">{t("reservation.tva")}</label>
                            <input id="tva" type="tva" value={TVA} onChange={handleTVAChange} />
                        </div>
                    </div>
                    <div className="reservation-customer">
                        <div className="reservation-customer-info">
                            <label htmlFor="adult">
                                {t("reservation.adult")}
                                {fieldEmpty && !adult && (
                                    <span>{t("reservation.obligatoryField")}</span>
                                )}
                                <span className="reservation-info-bubble" title={t("reservation.adultInfo")+tax+t("reservation.adultInfo2")}>?</span>
                            </label>
                            <input id="adult" type="number" value={adult} onChange={handleAdultChange} />
                        </div>
                        <div className="reservation-customer-info">
                            <label htmlFor="number">{t("reservation.child")}{fieldEmpty && !child &&(<span>{t("reservation.obligatoryField")}</span>)}</label>
                            <input id="child" type="number" value={child} onChange={handleChildChange} />
                        </div>
                    </div>
                    <div className="reservation-gite-selector">
                        <select
                            value={selectedGite}
                            onChange={handleChangeGite}
                        >
                            <option value="">{t("reservation.selection")}</option>
                            {gites.map((gite) => (
                                <option key={gite.id} value={gite.id}>{gite.name}</option>
                            ))}
                        </select>
                        <div className="reservation-price-per-night">
                            ~{Math.round(totalPrice / nights) || 0}€/{t("reservation.byNight")}{isDoublePeriod && (<span title={t("reservation.doublePeriod")}>*</span>)}
                        </div>
                    </div>
                    <div className="reservation-dates">
                        <ReactDatePicker
                            selected={arrivalDate}
                            onChange={handleArrivalDateChange}
                            excludeDates={unavailableStartDates}
                            minDate={minArrivalDate}
                            disabled={!selectedGite}
                            locale="fr"
                            dateFormat="dd-MM-yyyy"
                            placeholderText={t("reservation.arrivalDate")}
                            className="reservation-dates-calendar"
                            customInput={<CustomArrivalInputComponent />}
                        />
                        <ReactDatePicker
                            selected={departureDate}
                            onChange={date => setDepartureDate(date)}
                            excludeDates={unavailableEndDates}
                            minDate={arrivalDate ? new Date(new Date(arrivalDate).setDate(new Date(arrivalDate).getDate() + 1)) : new Date()}
                            maxDate={maxDepartureDate}
                            disabled={!selectedGite || !arrivalDate}
                            locale="fr"
                            dateFormat="dd-MM-yyyy"
                            placeholderText={t("reservation.departureDate")}
                            className="reservation-dates-calendar"
                            customInput={<CustomDepartureInputComponent />}
                        />
                        {fieldEmpty && !departureDate &&(<span>{t("reservation.obligatoryField")}</span>)}
                        {!nights && fieldEmpty && departureDate && (<span>{t("reservation.minimumNight")}</span>)}
                    </div>
                    <div className="reservation-total-price">
                        ~{Math.round(totalPrice / nights) || 0} € x {nights} {t("reservation.night")} <span> {t("reservation.cleaning")}</span> <span>{t("reservation.whichIs")} {totalPrice}€ TTC</span>
                    </div>
                    <div className="reservation-checkCondition">
                        <label>
                            <input
                                type="checkbox"
                                checked={isConditionChecked}
                                onChange={handleCheckboxChange}
                            />
                            {t("reservation.condition1")}<Link to="/terms-of-sale">{t("reservation.conditionLink")}</Link>{t("reservation.condition2")}{fieldEmpty && !isConditionChecked &&(<span>{t("reservation.obligatoryField")}</span>)}
                        </label>
                    </div>
                    <button className="reservation-pay" onClick={handlePayment}>{t("reservation.pay")}</button>
                </div>
            </div>
            )}
            {showPaymentForm && (
                <Elements stripe={stripePromise} options={{ appearance }}>
                    <CheckoutForm 
                      reservationId={reservationId}
                      email={email}
                      selectedGite={selectedGite}
                      arrivalDate={arrivalDate}
                      departureDate={departureDate}
                      setClientSecret={setClientSecret}
                      setShowPaymentForm={setShowPaymentForm}
                      apiUrl={apiUrl}
                      child={child}
                      adult={adult}
                    />
                </Elements>
            )}
        </main>
    );
};

export default Reservation;