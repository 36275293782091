import React, { useState } from 'react';
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom'; 
import { useLanguage } from "../LanguageContext";

const CheckoutForm = ({ reservationId, email, selectedGite, arrivalDate, departureDate, setClientSecret, setShowPaymentForm, apiUrl, child, adult }) => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate(); 
  const { language } = useLanguage();

  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!stripe || !elements) {
      setMessage("Stripe n'a pas encore chargé.");
      setIsLoading(false);
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        email: email,
      },
    });

    if (error) {
      console.log('[error]', error);
      setMessage(error.message);
      setIsLoading(false);
      return;
    } else {

      const response = await fetch(`${apiUrl}/validatePayment`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          paymentMethodId: paymentMethod.id,
          email,
          giteId: selectedGite,
          arrivalDate,
          departureDate,
          reservationId,
          language,
          nbChildren: child,
          nbAdult: adult
        }),
      });

      const paymentIntentResponse = await response.json();
      if (paymentIntentResponse.error) {
        setMessage(paymentIntentResponse.error);
        setIsLoading(false);
      } else {
        setClientSecret(paymentIntentResponse.clientSecret);
  
        const confirmResult = await stripe.confirmCardPayment(paymentIntentResponse.clientSecret);
        if (confirmResult.paymentIntent.status === 'succeeded') {

          setMessage('Paiement réussi !');
          setShowPaymentForm(false);
      
          navigate(`${process.env.REACT_APP_RETURN_URL}?reservationId=${paymentIntentResponse.reservationId}`)
        } else {

          setMessage('Le paiement a échoué, veuillez réessayer.');
        }
      }
    }

    setIsLoading(false);
  };

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "#32325d",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4"
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    }
  };
  
  return (
    <div className="checkout-container">
      <form onSubmit={handleSubmit} className="checkout-form">
  <div className="checkout-element-container">
    <label htmlFor="cardNumber" className="checkout-label">Numéro de carte</label>
    <CardNumberElement id="cardNumber" options={CARD_ELEMENT_OPTIONS} />
  </div>
  <div className="checkout-elements-container">
    <div className="checkout-date-container">
      <label htmlFor="cardExpiry" className="checkout-label">Date d'expiration</label>
      <CardExpiryElement id="cardExpiry" options={CARD_ELEMENT_OPTIONS} />
    </div>
    <div className="checkout-cvc-container">
      <label htmlFor="cardCvc" className="checkout-label">CVC</label>
      <CardCvcElement id="cardCvc" options={CARD_ELEMENT_OPTIONS} />
    </div>
  </div>
  <div className="checkout-bouton-container">
    <button disabled={isLoading} type="submit" className="checkout-pay">
      {isLoading ? 'Traitement…' : 'Payer'}
    </button>
  </div>
  {message && <div className="checkout-message">{message}</div>}
</form>


    </div>
  );
};

export default CheckoutForm;
